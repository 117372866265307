<template>
  <div class="content">
    <div class="head" id="head">
      <div class="headContent">
        <div class="logoNav">
          <div class="logo"></div>
          <a class="navBtn" @click="scrollTo('companyProfile', 0)"
            >公司简介<i v-show="activeIndex == 0"></i
          ></a>
          <a class="navBtn" @click="scrollTo('productCenter', 1)"
            >产品中心<i v-show="activeIndex == 1"></i
          ></a>
          <a class="navBtn" @click="scrollTo('profileMain', 2)"
            >品牌理念<i v-show="activeIndex == 2"></i
          ></a>
          <a class="navBtn" @click="scrollTo('contactUs', 3)"
            >联系我们<i v-show="activeIndex == 3"></i
          ></a>
        </div>
        <div class="vCode">
          <div class="title">
            <div class="icon"></div>
            <div>关注九乘公众号</div>
          </div>
          <div class="vCodePic"></div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="banner mg-80">
        <div class="carousel">
          <a-carousel autoplay :autoplay-speed="5000">
            <div><img object-fit src="./assets/banner1.png" alt="" /></div>
            <div><img object-fit src="./assets/banner2.png" alt="" /></div>
            <div><img object-fit src="./assets/banner3.png" alt="" /></div>
          </a-carousel>
        </div>
      </div>
      <!-- 公司简介 -->
      <div class="companyProfile mg-80 module" id="companyProfile">
        <div class="commonTitleBox">
          <div class="title">公司简介</div>
          <div class="subTitle">Company Introduction</div>
        </div>
        <div class="commonText">
          长沙九乘科技有限公司是一家拥有深厚军工背景，致力于新兴的低空安防领域、无人机监管领域和鸟情探测领域的技术研发、装备制造、市场开拓的创新型高科技企业。公司为行业客户提供高性能低空立体安防系统装备及全面解决方案、无人机智能监管产品与服务，广泛应用于要域防空、空域安全、大型活动安保、反恐维稳等多种领域。公司在雷达目标动态识别、电子侦察和预警、自动干扰反制、反无人机总体技术等领域获得多项科研成果和发明专利，自主研发了10余种专业产品。已部署于机场、边海防、政府部门、大型会议场所和军事基地，并已进入国际市场。
        </div>
      </div>
      <!-- 产品中心 -->
      <div class="productCenter mg-80 module" id="productCenter">
        <div class="commonTitleBox">
          <div class="title">产品中心</div>
          <div class="subTitle">Product Center</div>
        </div>
        <div class="productItem">
          <div class="text_item ltext">
            <div class="textTitle">低空反无系统 <i>◥</i></div>
            <div class="textMain">
              低空反无人机综合解决方案，以低空空域管理为基础，以无人机与操作员的准入管理为前提，对空域内无人机、操作员、飞行任务以及其他飞行物实施全方位安全监控和必要的运行管控，为规范低空空域秩序、防范及查处非法飞行提供强有力的技术支撑。反无人机综合解决方案，由无人机智能监控平台、低空安防管控平台、以及配套的飞行管理中心、智能监控中心和通信基础设施组成，实现无人机审、侦、监、控、扰、毁、罚全要素管理闭环。
            </div>
          </div>
          <div class="pic_item lowAltitude"></div>
        </div>
        <div class="productItem">
          <div class="pic_item avianEcology"></div>
          <div class="text_item lText">
            <div class="textTitle rText"><i>◤</i> 鸟情生态监测系统</div>
            <div class="textMain">
              系统采用低空鸟情监测雷达、高性能光电设备，植被、水文、气象监测传感器等设备实时采集保护区及周边鸟类活动和环境信息，记录鸟类栖息过程中的飞行轨迹、活动图像和视频、环境数据等，形成鸟情生态数据库；通过智能化鸟情生态大数据软件自动识别各种鸟类，统计、分析和数据挖掘本地鸟情生态的活动规律、生长趋势、种群分布、生境变化及其关联关系，为鸟类保护和生态环境治理提供智能化装备、精确数据和科学依据。
            </div>
          </div>
        </div>
        <div class="productItem">
          <div class="text_item lText">
            <div class="textTitle">“乘达豹”燃气管线监测系统 <i>◥</i></div>
            <div class="textMain">
              “乘达豹”燃气管道巡检监护系统是通过构建燃气管道综合管理的动态监测与数据传输网络系统，通过增加智能传感器，利用物联网技术，实现对管道压力变化、燃气泄漏、周边环境剧变、管网附近剧烈震动等信息的监测、数据采集、传输，实时记录和分析燃气管道运行安全数据，实现燃气管道位置信息、燃气输送状态、周围环境变化等信息的集成以及监测系统运行状态的动态安全监管。
            </div>
          </div>
          <div class="pic_item gasPipeline"></div>
        </div>
        <div class="productItem">
          <div class="pic_item airport"></div>
          <div class="text_item lText">
            <div class="textTitle rText"><i>◤</i> 机场低空防入侵平台</div>
            <div class="textMain">
              低空反无人机综合解决方案，以低空空域管理为基础，以无人机与操作员的准入管理为前提，对空域内无人机、操作员、飞行任务以及其他飞行物实施全方位安全监控和必要的运行管控，为规范低空空域秩序、防范及查处非法飞行提供强有力的技术支撑。反无人机综合解决方案，由无人机智能监控平台、低空安防管控平台、以及配套的飞行管理中心、智能监控中心和通信基础设施组成，实现无人机审、侦、监、控、扰、毁、罚全要素管理闭环。
            </div>
          </div>
        </div>
        <div class="productItem">
          <div class="text_item lText">
            <div class="textTitle">反无人机箱式察打一体系统” <i>◥</i></div>
            <div class="textMain">
              无人机作战的运用在国外已经引起了高度重视，它们具有改变未来战争方式的潜力。在俄乌冲突中，无人机作为主战装备，其作战效果和潜力得到了充分的发挥。无人集群可实施全方位、立体式饱和攻击，也可与有人机协同作战，提高作战灵活性，更可与巡航导弹等空中打击武器协同攻击，增强打击效果。
              针对这一场景，反无系统从客户需求出发，综合运用了包括探测跟踪和监控预警技术、高功率微波武器和激光武器定向能反无人机能力以及干扰阻断等多种技术，整合了应用人工智能的多种技术，能够探测、识别、跟踪无人机，实现对目标无人机的监测控制、干扰阻断甚至直接摧毁。/无人操作，并与飞行员进行必要性沟通，实现鸟情探测、定位、识别、驱离的自链式综合防范应用。
            </div>
          </div>
          <div class="pic_item counter-Unmanned"></div>
        </div>
      </div>

      <div class="slogans mg-80">
        <div class="blod">创新是我们与生俱来的基因，是我们的灵魂！</div>
        <div class="blod" style="margin-bottom: 24px">
          "Innovation is ingrained in our genes, it's our soul!"
        </div>
        <div class="subTitle">
          长沙九乘信息科技有限公司是一家拥有深厚军工背景，致力于开拓国内外新兴的反无人机行业和无人机智能监管行业技术研发、裝备制造、市场开拓的创新型高科技企业。
        </div>
      </div>
      <!-- 品牌理念 -->
      <div class="companyProfile mg-80 module" id="profileMain">
        <div class="commonTitleBox">
          <div class="title">品牌理念</div>
          <div class="subTitle">Brand Philosophy</div>
        </div>
        <div class="profileMain">
          <div class="brandPhilosophy_icon"></div>
          <div class="profileText">
            <p>
              <span>企业宗旨</span
              >以优异的产品、卓越的服务满足客户日益增长的实际需要
            </p>
            <p>
              <span>企业使命</span>全面构建安全有序的无人机飞行环境和低空环境
            </p>
            <p><span>经营理念</span>一切以用户满意为目标</p>
            <p><span>企业文化</span>艰苦奋斗、享受创新</p>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="banner_bottom" id="contactUs">
        <div class="main">
          <div class="contactContent">
            <div class="contactText">
              <div class="title">联系我们</div>
              <div class="subTitle">Contact Us</div>
              <div class="normalText"><span>电话：</span>0731-85114608</div>
              <div class="normalText">
                <span>邮箱：</span>jskj@csjs.ntesmail.com
              </div>
              <div class="normalText">
                <span>地址：</span>长沙市望城区金甲冲路新一代半导体研究院
              </div>
            </div>
            <div class="vrCode"></div>
          </div>
        </div>
        <div class="copyright">
          湘ICP备2023026395号-1 Copyright © 2022-2024 JIUSHENG  &nbsp;&nbsp;&nbsp; <img style="height: 16px; width: auto;"  src="https://beian.mps.gov.cn/web/assets/logo01.6189a29f.png" alt=""> 湘公网安备 <a href="https://beian.mps.gov.cn/#/query/webSearch?code=43011202001081">43011202001081</a> 号
        </div>
      </div>
    </div>
    <div class="backTop" @click="backTop"></div>
  </div>
</template>

<script setup>
import { ref } from "vue";
let activeIndex = ref(null);

const backTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

const scrollTo = (id, index) => {
  activeIndex.value = index;
  const dom = document.getElementById(id);
  if (!dom) return;
  window.scrollTo({ top: dom.offsetTop - 100, left: 0, behavior: "smooth" });
};
</script>

<style scoped lang="less">

a{
  color: #7e7e7e;
  text-decoration: none;
}
div {
  box-sizing: border-box;
}
.content {
  display: flex;
  flex-direction: column;
  .head {
    width: 100%;
    height: 97px;
    background: #0a0c1c;
    position: sticky;
    top: 0;
    padding: 0 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 99;
    .headContent {
      width: 1320px;
      position: relative;
    }
    .logoNav {
      display: flex;
      align-items: center;
      gap: 88px;
      .logo {
        width: 175px;
        height: 41px;
        background: url(./assets/logo.png) 100% 100% no-repeat;
      }
      .navBtn {
        cursor: pointer;
        width: 72px;
        height: 24px;
        color: #fff;
        font-family: "Microsoft YaHei";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: none;
        position: relative;
        i {
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 100%;
          height: 2px;
          background: #0377fd;
        }
      }
    }
    .vCode {
      cursor: pointer;
      font-size: 14px;
      display: flex;
      padding: 8px;
      align-items: center;
      flex-direction: column;
      background: #0a0c1c;
      /* height: 85px; */
      border-radius: 17px;
      position: absolute;
      border: 2px solid rgba(255, 255, 255, 0.8);
      right: 0;
      top: 0;
      &:hover {
        .vCodePic {
          display: block;
        }
        .title {
          margin-bottom: 12px;
        }
        padding-bottom: 16px;
      }
      .vCodePic {
        width: 114%;
        height: 114px;
        display: none;
        background: url(./assets/vCode.png) 100% 100% no-repeat;
        background-position: center;
      }

      .title {
        display: flex;
        gap: 12px;
      }
      .icon {
        width: 18px;
        height: 18px;
        background: url(./assets/vCode_icon.png) no-repeat 100% 100%;
      }
    }
  }
  .main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .commonText {
      color: #7e7e7e;
      text-align: center;
      font-family: "Microsoft YaHei";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 200%; /* 32px */
      text-align: start;
      text-indent: 2em;
    }
    .commonTitleBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      .title {
        color: #333;
        font-family: "Microsoft YaHei";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
      }
      .subTitle {
        color: #0377fd;
        font-family: "Microsoft YaHei";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }
    .banner {
      width: 100%;
      height: 760px;
      background: url(./assets/banner.png) no-repeat;
      background-position: top;
      background-size: 100%;
      display: flex;
      justify-content: center;
      .carousel {
        width: 1076px;
        height: 100%;
        img {
          width: 100%;
          height: 760px;
        }
        :deep(.slick-dots) {
          li {
            background: #d8d8d8;
          }
          .slick-active {
            button {
              background: #0377fd;
            }
          }
        }
      }
    }
    .banner_bottom {
      width: 100%;
      height: 389px;
      background: url(./assets/banner.png) no-repeat;
      background-position: top;
      background-size: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .copyright {
        color: #7e7e7e;
        font-family: "Microsoft YaHei";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%);
      }
      .main {
        width: 100%;
        height: 100%;
        padding: 0 300px;
        .contactContent {
          width: 1198px;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .contactText {
            display: flex;
            flex-direction: column;
            color: #fff;
            font-family: "Microsoft YaHei";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            .title {
              color: #fff;
              font-family: "Microsoft YaHei";
              font-size: 32px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 8px;
            }
            .subTitle {
              color: #0377fd;
              font-family: "Microsoft YaHei";
              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: uppercase;
              margin-bottom: 12px;
            }
            .normalText {
              margin-bottom: 8px;
            }
          }
          .vrCode {
            width: 115px;
            height: 157px;
            background: url(./assets/bottomVrCode.png) 100% 100% no-repeat;
          }
        }
      }
    }
    .module {
      width: 1296px;

      .profileMain {
        width: 100%;
        height: 207px;
        display: flex;
        justify-content: space-between;
        gap: 30px;
        .brandPhilosophy_icon {
          width: 636px;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(./assets/brandPhilosophy_icon.png);
          border-radius: 20px;
        }
        .profileText {
          color: #7e7e7e;
          font-family: "Microsoft YaHei";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          p {
            line-height: 1;
            margin: 0;
            span {
              color: #333;
              font-family: "Microsoft YaHei";
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              margin-right: 20px;
            }
          }
        }
      }
      .productItem {
        width: 100%;
        height: 366px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        gap: 30px;
        .lText {
          text-align: left;
        }
        .rText {
          text-align: right;
        }
        .text_item {
          min-width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          .textTitle {
            color: #333;
            font-family: "Microsoft YaHei";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 16px;
            i {
              color: #0377fd;
            }
          }
          .textMain {
            color: #7e7e7e;
            font-family: "Microsoft YaHei";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 200%; /* 32px */
            text-indent: 2em;
          }
        }
        .pic_item {
          width: 636px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        .lowAltitude {
          background-image: url(./assets/lowAltitude.png);
        }
        .avianEcology {
          background-image: url(./assets/avianEcology.png);
        }
        .gasPipeline {
          background-image: url(./assets/gasPipeline.png);
        }
        .airport {
          background-image: url(./assets/airport.png);
        }
        .counter-Unmanned {
          background-image: url(./assets/counter-Unmanned.png);
        }
      }
    }
    .slogans {
      width: 98%;
      padding: 72px 387px 60px 387px;
      border-radius: 36px;

      background: #f3f4f5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .blod {
        color: #000;
        text-align: center;
        font-family: "Microsoft YaHei";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .subTitle {
        color: #7e7e7e;
        text-align: center;
        font-family: "Microsoft YaHei";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
      }
    }
  }
  .mg-80 {
    margin-bottom: 80px;
  }
  .backTop {
    width: 46px;
    height: 46px;
    cursor: pointer;
    background: url(./assets/backTop.png) 100% 100% no-repeat;
    position: fixed;
    bottom: 50px;
    right: 50px;
  }
}
</style>
